<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Purchasing Payment Invoice : {{PurchasingPaymentInvoiceCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nomor Bukti</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="PurchasingPaymentInvoiceCode" v-model="PurchasingPaymentInvoiceCode" class="font-weight-bold"/>
                            <label id="errorPurchasingPaymentInvoiceCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Supplier</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="SupplierName" v-model="SupplierName" class="font-weight-bold" readonly/>
                            <label id="errorSupplierName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="PurchasingPaymentInvoiceDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorPurchasingPaymentInvoiceDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Payment Method</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="PaymentMethod" class="font-weight-bold" :options="PaymentMethodData" :value.sync="PaymentMethod" />
                            <label id="errorPaymentMethod" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Via Bank</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="ViaBank" class="font-weight-bold" :options="ViaBankData" :value.sync="ViaBank" />
                            <label id="errorViaBank" class="form-error" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Cabang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Branch" v-model="Branch" class="font-weight-bold"/>
                            <label id="errorBranch" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">No. Account</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="AccountNumber" v-model="AccountNumber" class="font-weight-bold"/>
                            <label id="errorAccountNumber" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Nama Account</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="AccountName" v-model="AccountName" class="font-weight-bold"/>
                            <label id="errorAccountName" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mata Uang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Currency" v-model="Currency" class="font-weight-bold" readonly/>
                            <label id="errorCurrency" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col">
                            <label style="float: right;"><h1><span v-html="Symbol"></span> {{TotalPayment}}</h1></label>
                        </div>
                    </CRow>
                    <br>
                    <CRow class="px-3" >
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Detail</h4></label>
                            <hr>
                            <div v-if="this.FormType == 'Add'">
                                <datasource ref="invoiceDataSource" :data="this.InvoiceGridData" :page-size="10" :schema-model-fields="this.InvoiceSchemaModel" :aggregate="this.InvoiceGridAggregate"/>
                            </div>
                            <div v-if="this.FormType == 'Edit'">
                                <datasource ref="invoiceDataSource" :data="this.InvoiceGridData" :page-size="10" :schema-model-fields="this.InvoiceEditSchemaModel" :aggregate="this.InvoiceGridAggregate"/>
                            </div>

                            <kendo-grid ref="gridInvoice"
                                        :data-source-ref="'invoiceDataSource'"
                                        :pageable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :editable="true"
                                        :cellClose="invoiceCellClose"
                                        :resizable="true"
                                        >
                                <kendo-grid-column  :field="'invoice_number'"
                                                    :title="'Nomor Invoice'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'total'"
                                                    :title="'Nilai Invoice'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'> ` + this.Symbol + ` #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'paid'"
                                                    :title="'Terbayar'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'> ` + this.Symbol + ` #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'left'"
                                                    :title="'Jumlah Bayar'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'> ` + this.Symbol + ` #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'sisa'"
                                                    :title="'Sisa'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    :aggregates="['sum']"
                                                    :footerTemplate="` <div style='float: right'> ` + this.Symbol + ` #=  kendo.toString(sum, 'n') #</div>`"></kendo-grid-column>

                                <kendo-grid-column  :field="'tax_invoice'"
                                                    :title="'No. Faktur PPN'"
                                                    :width="150"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'notes'"
                                                    :title="'Catatan'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                     <hr>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pembulatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="RoundUp" v-model="RoundUp" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorRoundUp" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Ongkos Kirim</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="DeliveryFee" v-model="DeliveryFee" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorDeliveryFee" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Biaya Admin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="AdminFee" v-model="AdminFee" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorAdminFee" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow class="pb-3">
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                        </div>
                        <div class="col-sm-content">
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">PPH</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="OtherFee" v-model="OtherFee" class="font-weight-bold form-control input-number" @change="countTotalPayment"/>
                            <label id="errorOtherFee" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <label id="errorPurchasingPaymentInvoiceGeneral" class="form-error pb-2" style="display: none; color: red;"></label>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/purchasing-payment-invoice')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import purchasingPaymentInvoiceServices from '../Script/PurchasingPaymentInvoiceServices.js';
import CurrencyService from '../../../MasterData/Currency/Script/CurrencyService.js';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import moment from 'moment';

export default {
    name: 'PurchasingPaymentInvoicePage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    async mounted () {
        if(this.FormType == 'Add'){
            this.SaveType = 'Add';

            var data = await purchasingPaymentInvoiceServices.getSinglePayableInvoiceQuery(this.InvoiceId);

            this.PurchasingPaymentInvoiceCode = 'Auto';
            this.SupplierId = data.supplier_id;
            this.SupplierName = data.supplier_name;
            this.PurchasingPaymentInvoiceDate = moment(data.invoice_date).format("YYYY-MM-DD");
            this.PaymentMethod = 'Unknown';
            this.ViaBankData = await purchasingPaymentInvoiceServices.getBankQuery();
            this.ViaBank = 'Unknown';
            this.Branch = '';
            this.AccountNumber = '';
            this.AccountName = '';
            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchasing_invoice_payment');
            this.Status = 'Unclear';
            this.Note = '';

            var currencyData = await CurrencyService.getCurrencyById(data.currency_id);
            this.Currency = currencyData.currency_code;
            this.CurrencyId = data.currency_id;
            this.Symbol = currencyData.currency_symbol;
            
            //Grid
            var gridItemData = await purchasingPaymentInvoiceServices.getPayableInvoiceQuery(this.InvoiceId);
            this.OldInvoiceGridData = gridItemData;
            this.InvoiceGridData = await purchasingPaymentInvoiceServices.countSisaAmount(gridItemData, this.OldInvoiceGridData);
            this.countTotalPayment();

            document.getElementById("PurchasingPaymentInvoiceCode").readOnly = false;
        }
        else {
            this.SaveType = 'Edit';
            
            var data = await purchasingPaymentInvoiceServices.getPayableInvoiceByIdQuery(this.InvoiceId);
            
            this.PurchasingPaymentInvoiceCode = data.inv_payment_number;
            this.SupplierId = data.supplier_id;
            this.SupplierName = data.supplier_name;
            this.PurchasingPaymentInvoiceDate = moment(data.inv_payment_date).format("YYYY-MM-DD");
            this.PaymentMethod = data.payment_method;
            this.ViaBankData = await purchasingPaymentInvoiceServices.getBankQuery();
            this.ViaBank = data.bank;
            this.Branch = data.branch;
            this.AccountNumber = data.account_number;
            this.AccountName = data.account_name;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchasing_invoice_payment');
            this.Status = data.status;
            this.Note = data.notes;
            
            var currencyData = await CurrencyService.getCurrencyById(data.currency_id);
            this.Currency= currencyData.currency_code;
            this.CurrencyId = data.currency_id;
            this.Symbol = currencyData.currency_symbol

            this.RoundUp = data.round_up;
            this.DeliveryFee = data.delivery_fee;
            this.AdminFee = data.admin_fee;
            this.OtherFee = data.other_fee;

            var griddata = purchasingPaymentInvoiceServices.countSisaAmountEdit(data.purchasing_invoice_payment_detail);

            //Grid
            this.InvoiceGridData = griddata;
            this.countTotalPayment();

            document.getElementById("PurchasingPaymentInvoiceCode").readOnly = true;
            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            CheckAll: this.$route.params.checkall,
            Error : 0,

            //Detail
            InvoiceId: this.$route.params.id,
            PurchasingPaymentInvoiceCode: '',
            SupplierId: '',
            SupplierName: '',
            PurchasingPaymentInvoiceDate: '',
            PaymentMethodData: [ 
                {value: 'Unknown', label: 'Unknown'},
                {value: 'Cash', label: 'Cash'},
                {value: 'Debit Card', label: 'Debit Card'},
                {value: 'Credit Card', label: 'Credit Card'},
                {value: 'Bank Transfer', label: 'Bank Transfer'},
                {value: 'Auto Debet', label: 'Auto Debet'},
                {value: 'Auto Debet Credit Card', label: 'Auto Debet Credit Card'},
                {value: 'Cheque', label: 'Cheque'},
                {value: 'Giro', label: 'Giro'} 
            ],
            PaymentMethod: '',
            ViaBankData: [],
            ViaBank: '',
            Branch: '',
            AccountNumber: '',
            AccountName: '',
            StatusData: [],
            Status: '',
            Note: '',
            Currency: '',
            CurrencyId: '',
            TotalPayment: '',
            TotalPaymentAmount: 0,
            RoundUp: 0,
            DeliveryFee: 0,
            AdminFee: 0,
            OtherFee: 0,

            SaveType: '',
            Symbol: '',

            //grid
            InvoiceGridData: [],
            InvoiceSchemaModel: {
                invoice_number: { type: "string", editable: false },
                total: { type: "number", editable: false },
                paid: { type: "number", editable: false },
                left: { type: "number", editable: true, validation: { min:0 } },
                sisa: { type: "number", editable: false },
                tax_invoice: { type: "string", editable: false },
                notes: { type: "string", editable: true },
            },
            InvoiceEditSchemaModel: {
                invoice_number: { type: "string", editable: false },
                total: { type: "number", editable: false },
                paid: { type: "number", editable: false },
                left: { type: "number", editable: false },
                sisa: { type: "number", editable: false },
                tax_invoice: { type: "string", editable: false },
                notes: { type: "string", editable: false },
            },
            InvoiceGridAggregate:[
                { field: "total", aggregate: "sum" },
                { field: "paid", aggregate: "sum" },
                { field: "left", aggregate: "sum" },
                { field: "sisa", aggregate: "sum" }
            ],

            OldInvoiceGridData: [],
        }
    },
    methods: {
        async invoiceCellClose(e){
            var dataItem = e.sender.dataItem($(e.container).parent());
            var oldDataItem = this.OldInvoiceGridData;
            var singleOldDataItem = oldDataItem.filter(item => item.invoice_id == dataItem.invoice_id);
            
            if(dataItem.left == null || dataItem.left < 1){
                this.$swal("Info", "Jumlah Bayar tidak boleh kosong atau 0", "info");
                
                dataItem.left = Math.round(singleOldDataItem[0].left);
                dataItem.paid = Math.round(singleOldDataItem[0].paid);
                dataItem.sisa = 0;
            }
            else if(dataItem.left > Math.round(singleOldDataItem[0].left)){
                this.$swal("Info", "Jumlah Bayar tidak boleh melebihi Nilai Invoice", "info");
                
                dataItem.left = Math.round(singleOldDataItem[0].left);
                dataItem.paid = Math.round(singleOldDataItem[0].total);
                dataItem.remaining = 0;

                var gridItem = this.$refs.gridInvoice.kendoWidget().dataSource._data;
                this.InvoiceGridData = purchasingPaymentInvoiceServices.countSisaAmount(gridItem, oldDataItem);
            }
            else{
                var gridItem = this.$refs.gridInvoice.kendoWidget().dataSource._data;
                var newData = purchasingPaymentInvoiceServices.countSisaAmount(gridItem, oldDataItem)
                
                this.InvoiceGridData = newData;
            }

            this.countTotalPayment();
        },
        countTotalPayment(){
            if(this.RoundUp == ''){
                this.RoundUp = 0;
            }
            if(this.DeliveryFee == ''){
                this.DeliveryFee = 0;
            }
            if(this.AdminFee == ''){
                this.AdminFee = 0;
            }
            if(this.OtherFee == ''){
                this.OtherFee = 0;
            }
            var gridData = this.InvoiceGridData;
            var RoundUp = parseInt(this.RoundUp);
            var DeliveryFee = parseInt(this.DeliveryFee);
            var AdminFee = parseInt(this.AdminFee);
            var OtherFee = parseInt(this.OtherFee);
            var totalGrid = 0;
            
            if(gridData.length > 0){
                for (let i = 0; i < gridData.length; i++) {
                    totalGrid = totalGrid + gridData[i].left;
                }
            }
            
            this.TotalPaymentAmount = totalGrid + RoundUp + DeliveryFee + AdminFee + OtherFee;
            this.TotalPayment = kendo.toString((totalGrid + RoundUp + DeliveryFee + AdminFee - OtherFee), 'n');
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.PurchasingPaymentInvoiceCode == '' || this.PurchasingPaymentInvoiceCode == null){
                this.errorShow('errorPurchasingPaymentInvoiceCode');
            }
            if(this.PurchasingPaymentInvoiceDate == '' || this.PurchasingPaymentInvoiceDate == null){
                this.errorShow('errorPurchasingPaymentInvoiceDate');
            }
            // if(this.Branch == '' || this.Branch == null){
            //     this.errorShow('errorBranch');
            // }
            // if(this.AccountNumber == '' || this.AccountNumber == null){
            //     this.errorShow('errorAccountNumber');
            // }
            // if(this.AccountName == '' || this.AccountName == null){
            //     this.errorShow('errorAccountName');
            // }
            // if(this.Note == '' || this.Note == null){
            //     this.errorShow('errorNote');
            // }
            if(this.RoundUp.toString() == '' || this.RoundUp == null){
                this.errorShow('errorRoundUp');
            }
            else if (this.RoundUp < 0) {
                this.errorShow('errorRoundUp', response.fieldRequiredNotMinus);
            }

            if(this.DeliveryFee.toString() == '' || this.DeliveryFee == null){
                this.errorShow('errorDeliveryFee');
            }
            else if (this.DeliveryFee < 0) {
                this.errorShow('errorDeliveryFee', response.fieldRequiredNotMinus);
            }

            if(this.AdminFee.toString() == '' || this.AdminFee == null){
                this.errorShow('errorAdminFee');
            }
            else if (this.AdminFee < 0) {
                this.errorShow('errorAdminFee', response.fieldRequiredNotMinus);
            }

            if(this.OtherFee.toString() == '' || this.OtherFee == null){
                this.errorShow('errorOtherFee');
            }
            else if (this.OtherFee < 0) {
                this.errorShow('errorOtherFee', response.fieldRequiredNotMinus);
            }

        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){
                //item GridData
                var gridItem = this.$refs.gridInvoice.kendoWidget().dataSource._data;
                var purchasingInvoicePaymentDetail = this.$globalfunc.objectToArrayConverter(gridItem, "PurchasingPaymentInvoice-DetailData");

                var paymentType = 1;

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const purchasingPaymentInvoiceData = {
                        inv_payment_type: paymentType,
                        inv_payment_number: this.PurchasingPaymentInvoiceCode,
                        inv_payment_date: this.PurchasingPaymentInvoiceDate,
                        status: this.Status,
                        supplier_id: this.SupplierId,
                        currency_id: this.CurrencyId,
                        payment_method: this.PaymentMethod,
                        bank: this.ViaBank,
                        branch: this.Branch,
                        account_number: this.AccountNumber,
                        account_name: this.AccountName,
                        notes: this.Note,
                        round_up: parseInt(this.RoundUp),
                        delivery_fee: parseInt(this.DeliveryFee),
                        admin_fee: parseInt(this.AdminFee),
                        other_fee: parseInt(this.OtherFee),
                        total: parseFloat(this.TotalPaymentAmount),
                        purchasing_invoice_payment_detail: purchasingInvoicePaymentDetail
                    }
                    
                    const variables = {
                        data : purchasingPaymentInvoiceData
                    }
                    
                    purchasingPaymentInvoiceServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/purchasing-payment-invoice' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const purchasingPaymentInvoiceData = {
                        inv_payment_type: paymentType,
                        inv_payment_number: this.PurchasingPaymentInvoiceCode,
                        inv_payment_date: this.PurchasingPaymentInvoiceDate,
                        status: this.Status,
                        supplier_id: this.SupplierId,
                        currency_id: this.CurrencyId,
                        payment_method: this.PaymentMethod,
                        bank: this.ViaBank,
                        branch: this.Branch,
                        account_number: this.AccountNumber,
                        account_name: this.AccountName,
                        notes: this.Note,
                        round_up: parseInt(this.RoundUp),
                        delivery_fee: parseInt(this.DeliveryFee),
                        admin_fee: parseInt(this.AdminFee),
                        other_fee: parseInt(this.OtherFee),
                        purchasing_invoice_payment_detail: purchasingInvoicePaymentDetail
                    }

                    const variables = {
                        id : parseInt(this.InvoiceId),
                        data : purchasingPaymentInvoiceData
                    }

                    purchasingPaymentInvoiceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/purchasing-payment-invoice'
                                }
                            });
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
    } 
}
</script>

<style scoped>
</style>